/* eslint-disable @typescript-eslint/no-explicit-any */
import { Languages, ProductCardType } from '@/constants'
import { labelPositionProductCard } from '@/constants/positions'
import { useAuth, useNotification } from '@/containers'
import type { Product } from '@/types'
import { getTranslate } from '@/utils/api-interceptors'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useMemo } from 'react'
import { CompareAddIcon } from '../Icon/CompareAddIcon'
import ImageOptimize from '../ImageOptimize'
import Price from '../Price'
import ProgressBar from '../ProgressBar'
import Countdown from './Countdown'
import PreOrderCard from './PreOrderCard'

export interface ProductProps {
  product: Product
  type?: string
  layout?: number
  className?: string
  descClassName?: string
  imageClassName?: string
  canCompare?: boolean
  priority?: boolean
  handleAddToCompare?: () => void
}

const ProductCard = ({
  className,
  product,
  type,
  layout,
  descClassName = '',
  imageClassName = '',
  canCompare = false,
  handleAddToCompare,
}: ProductProps) => {
  const productName = useMemo(() => {
    return getTranslate(product.name)
  }, [product])
  const { systemConfig } = useNotification()
  const { token } = useAuth()
  const voucherDisplay = product?.coupons?.sort(
    (a: any, b: any) => b?.updatedAt - a?.updatedAt
  )[0]

  const router = useRouter()
  return (
    <div
      className={`sm:min-h-[380px] items-center overflow-hidden lg:min-h-[440px] ${
        className ? className : ''
      } ${type ? type : ''} ${
        type == ProductCardType.PRODUCT_LIST_CMS ? 'px-2.5' : 'itemProducts'
      } ${layout ? 'l' + layout : ''}`}
    >
      <div
        className={`image-thumb relative block w-full overflow-hidden rounded-t-sm`}
      >
        <Link
          href={'/' + getTranslate(product.slugs.value) + product.slugs.postfix}
          className={`relative block w-full overflow-hidden pt-[100%] focus:border-0 focus:outline-0`}
        >
          <ImageOptimize
            className={`absolute left-0 top-0 aspect-square h-full w-full object-cover p-4 ${imageClassName} ${
              type == ProductCardType.TRENDING && 'rounded-sm !p-0'
            } ${
              type == ProductCardType.RECOMMENDED || type == undefined
                ? 'rounded-sm'
                : ''
            } ${
              type == ProductCardType.PRODUCT_LIST_CMS
                ? 'bg-white'
                : 'productImage'
            }`}
            src={
              product.thumbnail
                ? product?.thumbnail
                : systemConfig?.imagePlaceholder ||
                  process.env.NEXT_PUBLIC_IMAGE_PLACEHOLDER ||
                  ''
            }
            alt={productName}
            width={300}
            height={300}
            loading={'lazy'}
            thumbnail
          />
        </Link>
        {product?.flashSale && type != ProductCardType.TRENDING && (
          <Countdown
            productId={product._id}
            endTime={product?.flashSale?.endTime}
            type={ProductCardType.PRODUCT_CARD}
          />
        )}
        {product?.labels &&
          product?.labels?.map((item, index) => {
            return (
              <span
                className={`${labelPositionProductCard.get(
                  item?.position
                )} label hiddenScrollBar absolute`}
                key={index}
                style={{ zIndex: 20 + index }}
              >
                <ImageOptimize
                  src={item?.image}
                  alt={getTranslate(item?.name)}
                  width={200}
                  height={48}
                  className='h-auto w-auto'
                />
              </span>
            )
          })}
      </div>
      <div
        className={`flex w-full flex-col flex-wrap bg-white-400 pt-2 text-left ${
          [
            ProductCardType.LIST_HOT,
            ProductCardType.PRODUCT_LIST_CMS,
            ProductCardType.PRODUCT_LIST_FULL_CMS,
            ProductCardType.PRODUCT_TAB_CMS,
            ProductCardType.FLASH_SALE,
          ].includes(type as ProductCardType)
            ? 'rounded-b-sm border-t-0 px-2.5 pb-3'
            : ''
        } ${descClassName}`}
      >
        {product.label && (
          <span className='mb-1 rounded border bg-info-background bg-opacity-10 px-[4px] py-[2px] text-mainBody uppercase text-info-text opacity-90'>
            {product?.label}
          </span>
        )}
        <Link
          href={'/' + getTranslate(product.slugs.value) + product.slugs.postfix}
          className='mb-1.5 line-clamp-2 h-[40px] w-full overflow-hidden text-ellipsis text-base capitalize leading-5 text-dark-400 focus:border-0 focus:outline-0'
        >
          {productName}
        </Link>
        <Price
          type={ProductCardType.PRODUCT_CARD}
          originPrice={product?.price}
          finalPrice={product?.finalPrice}
          link={'/' + getTranslate(product.slugs.value) + product.slugs.postfix}
        />
        <div className={`mt-1.5 h-[30px]`}>
          {product.preOrder && (
            <Link
              href={
                '/' + getTranslate(product.slugs.value) + product.slugs.postfix
              }
            >
              <PreOrderCard
                price={product?.preOrder?.salePrice}
                type={ProductCardType.PRODUCT_CARD}
              />
            </Link>
          )}
        </div>
        <div className={`list mt-1.5 h-[30px]`}>
          {product.flashSale && (
            <ProgressBar
              flashSale={product.flashSale}
              fsType={product.type}
              type={ProductCardType.PRODUCT_CARD}
            />
          )}
        </div>
        {canCompare && (
          <button
            onClick={handleAddToCompare}
            className='mx-auto flex items-center'
          >
            <CompareAddIcon className='mr-[5px]'></CompareAddIcon>
            <span className='text-blue'>So sánh</span>
          </button>
        )}
        <div
          className={`${
            type === 'flashSale' ||
            type === 'trending' ||
            type === 'recommended'
              ? 'h-[58px]'
              : ''
          }`}
        >
          {product?.coupons && product?.coupons.length > 0 && (
            <div
              className={`voucher mt-1 flex w-full cursor-pointer gap-1 rounded-lg border px-1 py-1.5 ${
                token
                  ? 'border-green-500 bg-green-200'
                  : 'border-red-100 bg-red-50'
              }`}
              onClick={() => {
                router.push(
                  '/' +
                    getTranslate(product.slugs.value) +
                    product.slugs.postfix
                )
              }}
            >
              <Image
                src={
                  token
                    ? '/assets/icons/voucher-icon-login.svg'
                    : '/assets/icons/voucher-icon.svg'
                }
                width={28}
                height={28}
                alt='voucher'
                className='w-7'
              />

              {voucherDisplay?.showValue ? (
                <div className='flex flex-col'>
                  <span
                    className={`text-mainBody font-bold ${
                      token ? ' text-green-900' : ' text-red-500'
                    }`}
                  >
                    Giảm{' '}
                    {(voucherDisplay?.percentValue &&
                      `${voucherDisplay?.percentValue}% `) ||
                      voucherDisplay?.fixedValue
                        ?.toLocaleString(Languages.VN, {
                          style: 'currency',
                          currency: 'VND',
                        })
                        .replace(/\s₫/g, 'đ')}
                    {voucherDisplay?.percentValue && (
                      <span
                        className={`text-mainBody ${
                          token
                            ? 'font-normal text-green-900'
                            : 'font-normal text-black-400'
                        }`}
                      >
                        (~
                        {voucherDisplay?.fixedValue &&
                          voucherDisplay?.fixedValue
                            ?.toLocaleString(Languages.VN, {
                              style: 'currency',
                              currency: 'VND',
                            })
                            .replace(/\s₫/g, 'đ')}
                        )
                      </span>
                    )}
                  </span>
                  <span
                    className={`text-mainBody ${
                      token
                        ? 'font-normal text-green-900'
                        : 'font-normal text-black-600'
                    }`}
                  >
                    {getTranslate(voucherDisplay?.name)}
                  </span>
                </div>
              ) : (
                <div className='flex flex-grow-0 text-xs'>
                  <span
                    className={`line-clamp-2 font-bold ${
                      token ? 'text-green-900' : 'text-black-400'
                    }`}
                  >
                    {getTranslate(voucherDisplay?.name)}
                  </span>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ProductCard
